(function($){
    $.fn.loadMoreFilterMasonry = function () {
        return this.each(function() {
            var initialLoad = true;
            var page = 1;
            var filtersChange = false;
            var filterSelector = $(this).data('filters-form');
            var typeFilterStr = '';
            var taxFilterStr = '';
            var metaFilterStr = '';
            var dateFilterStr = '';
            var orderFilterStr = '';  

            var $msnContainer = $(this).find('.js-masonry-container');
            var $loader = $(this).find('.js-loader');
            var $loadBtn = $(this).find('.js-load-more-btn');
            var $filtersForm = $(filterSelector);
            
            var $resetFilters = $filtersForm.find('.js-reset-filters');
            var initialTypes = $filtersForm.find('select[data-type=post_type]').val();

            var $postTypeSelect = $filtersForm.find('select[data-type=post_type]');
            
            $msnContainer.imagesLoaded( function() {
                $msnContainer.find('.js-masonry-item').removeClass('u-hide');
                $msnContainer.masonry({
                    itemSelector: '.js-masonry-item',
                    // use sizer element for option
                    columnWidth: '.js-masonry-sizer',
                    percentPosition: true
                });
                $loader.addClass('u-hide');

                if ( page < data.max_num_pages ) {
                    $loadBtn.removeClass('u-hide');
                }

                initialLoad = false;
            });

            //load more function
            var loadPosts = function() {
                $.ajax({
                  url: data.ajaxurl,
                  type: 'post',
                  data: {
                      'action': 'bfd_load_more_filter_masonry',
                      'paged': page,
                      '_ajax_nonce': data.nonce,
                      'query_vars' : data.query_vars,
                      'typeFilter': typeFilterStr,
                      'taxFilter' : taxFilterStr,
                      'metaFilter': metaFilterStr,
                      'dateFilter': dateFilterStr,
                      'orderFilter': orderFilterStr,
                      'filtersChange': filtersChange
                  },
                  beforeSend: function () {
                    $loadBtn.addClass('u-hide');
                    $loader.removeClass('u-hide');
                    if( filtersChange ) {
                      //empty the posts container: counter, no-results and items (leave the sizer) 
                      $msnContainer.find('.js-new-max-pages').remove();
                      $msnContainer.find('.js-no-results').remove();
                      $msnContainer.masonry( 'remove', $('.js-masonry-item') ).masonry('layout');
                    }
                  },
                  success: function (response) {
                    $msnContainer.append(response);

                    // http://codex.wordpress.org/AJAX_in_Plugins#The_post-load_JavaScript_Event
                    $(document.body).trigger('post-load');

                    var $new_elements = $('.js-masonry-item.u-hide');
                    $msnContainer.imagesLoaded( function() {
                      $msnContainer.find('.js-masonry-item.u-hide').removeClass('u-hide');
                      $msnContainer.masonry('appended', $new_elements);
                      $loader.addClass('u-hide');

                      // if filters have just changed
                      if( filtersChange === true ) {
                        filtersChange = false;
                        // update max_num_pages
                        data.max_num_pages = $msnContainer.find('.js-new-max-pages').data('count');
                      }

                      if ( page < data.max_num_pages ) {
                        $loadBtn.removeClass('u-hide');
                      }
                    });
                  }
                });
            };

            //load more button
            $loadBtn.on('click', function(event){
                event.preventDefault();
                page++;
                loadPosts();
            });

            //filters form
            $filtersForm.on('submit', function(e) {
              e.preventDefault();
              // get the new filters
              processFilters();
              // reset page
              page = 1;
              // do a new load
              loadPosts();
              //close modal
              $.magnificPopup.close();
            });

            //processFilters function
            var processFilters = function() {
              var typeFilter = [];
              var dateFilter = [];
              var taxFilter = {}; 
              var metaFilter = {};
              var orderFilter = {};

              // process post_type
              if( $postTypeSelect.val() !== null ) {
                typeFilter = $postTypeSelect.val();
              } 
              // process taxonomy selects (includes category)
              $filtersForm.find('select[data-type=tax]').each( function(){
                var $taxSelect = $(this);
                if( ( $taxSelect.val() !== null ) && ( ! $taxSelect.prop('disabled') ) ) {
                  taxFilter[$taxSelect.attr('name')] = $taxSelect.val();
                }
              });
              // process meta
              var $metaSelect = $filtersForm.find('select[data-type=meta]'); 
              if( ( $metaSelect.val() !== null ) && ( ! $metaSelect.prop('disabled') ) ) {
                metaFilter[$metaSelect.attr('name')] = $metaSelect.val();
              }
              // process date
              var $dateSelect = $filtersForm.find('select[data-type=date]'); 
              if( ( $dateSelect.val() !== null ) && ( ! $dateSelect.prop('disabled') ) ) {
                dateFilter = $dateSelect.val();
              }
              // process order
              $orderbySelect = $filtersForm.find('select[data-type=orderby]'); 
              orderFilter.orderby = $orderbySelect.val();
              $orderSelect = $filtersForm.find('select[data-type=order]'); 
              orderFilter.order = $orderSelect.val();
             
              // stringify the results 
              typeFilterStr = JSON.stringify(typeFilter);
              dateFilterStr = JSON.stringify(dateFilter);
              taxFilterStr = JSON.stringify(taxFilter);
              metaFilterStr = JSON.stringify(metaFilter);
              orderFilterStr = JSON.stringify(orderFilter);

              // set filtersChange
              filtersChange = true;
            };

            //rest filters button
            $resetFilters.on('click',function(e){
              e.preventDefault();
              $filtersForm.find('select[data-type=post_type]').val(initialTypes).trigger("change");
              $filtersForm.find('select[data-type=tax]').val(null).trigger("change");
              $filtersForm.find('select[data-type=meta]').val(null).trigger("change");
              $filtersForm.find('select[data-type=date]').val(null).trigger("change");
              $filtersForm.find('select[data-type=orderby]').val('date').trigger("change");
              $filtersForm.find('select[data-type=order]').val('DESC').trigger("change");
              return false;
            });

            //disable filters when post types select changes
            $postTypeSelect.on('change', function(){
              //disable all filters that have the apply data attribute
              $filtersForm.find('select[data-apply]').prop('disabled', true);
              var postTypes = $(this).val();
              if( postTypes ) {
                for( var i=0 ; i < postTypes.length ; i++ ) {
                  $filtersForm.find('select[data-apply~='+postTypes[i]+']').prop('disabled', false);
                }
              }
            });
        });
    };
})(jQuery);