/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var fwd = {
    // All pages
    common: {
      init: function () {
        /*****************************************************************/
        // Header & Navigation
        /*****************************************************************/
        // Throttle scroll for menu fixation
        pin_top_menu();
        $(window).scroll($.throttle(100, pin_top_menu));

        // navigation -----
        $('.js-site-nav-trigger').on('click', function (event) {
          event.preventDefault();
          $('body').toggleClass('is-locked'); //this permits to change styles on body - make overflow hidden, stopping scroll etc
          $('.c-site-nav').toggleClass('is-active');
          $('.c-site-head').toggleClass('is-active');
          $(this).toggleClass('is-active');
          event.stopPropagation();
        });

        // menu accordion
        $('.js-dropdown > a').on('click', function (event) {
          event.preventDefault();
          $(this).parent().toggleClass('is-open');
        });

        // open accordion of current menu item
        $('.js-dropdown.is-active').addClass('is-open');

        // open the dropdown of current menu item when we have custom menu highlights
        $('.is-dropdown .is-active')
          .closest('.submenu')
          .parent()
          .addClass('is-active')
          .addClass('is-open');

        // search -----
        // open seacrh drawer
        $('.js-site-search-trigger').on('click', function (event) {
          event.preventDefault();
          $('.js-site-search').toggleClass('is-active');

          if (!$('body').hasClass('is-locked')) {
            $('body').addClass('is-locked');
          }

          if ($('.js-site-search').hasClass('is-active')) {
            // the drawer just opened
            $('.js-site-search').find('input[type=search]').focus();
          }
          event.stopPropagation();
        });
        // close search drawer
        $('.js-site-search-close-trigger').on('click', function (event) {
          event.preventDefault();
          $('.js-site-search').removeClass('is-active');
          if (!$('.js-nav-overlay').hasClass('is-active')) {
            $('body').removeClass('is-locked');
          }
          event.stopPropagation();
        });
        // close drawer on document click
        $(document).on('click', function () {
          $('.js-site-search').removeClass('is-active');
          if (!$('.js-nav-overlay').hasClass('is-active')) {
            $('body').removeClass('is-locked');
          }
        });
        // prevent drawer from closing on click inside
        $('.js-site-search input').on('click', function (event) {
          event.stopPropagation();
        });

        /*****************************************************************/
        // General
        /*****************************************************************/

        // scroll button --------------
        $('.js-scroll-trigger').on('click', function (e) {
          e.preventDefault();
          var $target = $('.js-scroll-btn-target');
          animateScroll($target);
        });

        // hero slider --------------
        initHeroSlider();

        // load more init --------------
        $('.js-masonry-load-more').loadMoreMasonry();

        // bottom alinged widget in sidebar --------------
        setSidebarHeight();

        // modal --------------
        $('.js-popup-modal').magnificPopup({
          type: 'inline',
          modal: false,
        });

        // modal: false / closeOnBgClick: true - not working
        // close modal on background click
        $(document).on('click', '.c-modal', function (e) {
          $.magnificPopup.close();
          $('body').removeClass('has-modal-open');
          //If clipboard.js is open, return container to document.body
          if (typeof window.heateorSssClipboard != 'undefined')
            window.heateorSssClipboard.container = document.body;
        });
        $(document).on('click', '.c-modal__content', function (e) {
          e.stopPropagation();
        });
        $(document).on('click', '.mfp-close', function () {
          $('body').removeClass('has-modal-open');
          //If clipboard.js is open, return container to document.body
          if (typeof window.heateorSssClipboard != 'undefined')
            window.heateorSssClipboard.container = document.body;
        });

        // Set correct background images  --------------
        change_image_sources();

        // animate scroll to target link --------------
        $('body').on('click', '.js-scroll-to-href', function (e) {
          e.preventDefault();
          var target_id = $(this).attr('href');
          var $target = $(target_id);
          animateScroll($target);
        });

        // sticky element  --------------
        if ($('.js-sticky-in-parent').length) {
          var $header_height = $('.c-site-head').outerHeight();
          $('.js-sticky-in-parent').stick_in_parent({
            parent: '.js-parent-of-sticky',
            offset_top: $header_height,
          });
        }

        /*****************************************************************/
        // Open Posts in Modal

        $(document).on('click', '.js-open-in-modal', function (e) {
          if (testIsDesktop()) {
            e.preventDefault();
            var id = $(this).data('id');
            var url = $(this).attr('href');

            // close a modal if there's one opened
            $.magnificPopup.close();

            //check if there is already a modal for this item
            var $modal = $('#item-modal-' + id);
            if ($modal.length) {
              // open modal
              $.magnificPopup.open({
                items: {
                  src: $modal,
                  type: 'inline',
                  modal: false,
                },
              });
              $('body').addClass('has-modal-open');
            } else {
              // create the modal & append it to <main>
              $modal = $(
                '<div id="item-modal-' +
                  id +
                  '" class="mfp-hide c-modal">' +
                  '<div class="o-container c-modal__content c-modal__content--page">' +
                  '<div class="s-modal js-modal-content">' +
                  '</div>' +
                  '<div class="c-loader js-loader u-mt u-mb"></div>' +
                  '<button title="Close (Esc)" type="button" class="mfp-close c-modal__close c-modal__close--page">×</button>' +
                  '</div>' +
                  '</div>'
              );
              $('main').append($modal);

              // get the modal content with ajax
              $.ajax({
                url: data.ajaxurl,
                type: 'post',
                data: {
                  action: 'bfd_load_item_page',
                  _ajax_nonce: data.items_nonce,
                  id: id,
                },
                beforeSend: function () {
                  // init & open the modal
                  $.magnificPopup.open({
                    items: {
                      src: $modal,
                      type: 'inline',
                      modal: false,
                    },
                  });
                  $('body').addClass('has-modal-open');
                },
                success: function (response) {
                  // http://codex.wordpress.org/AJAX_in_Plugins#The_post-load_JavaScript_Event
                  $(document.body).trigger('post-load');
                  //hide the loader
                  $modal.find('.js-loader').addClass('u-hide');
                  //append the content
                  $modal.find('.js-modal-content').append(response);

                  // call all js functions for the single pages
                  initHeroSlider();
                  setSidebarHeight();
                  change_image_sources();

                  // make the modal prev/next links and related links open in modals as well
                  $('.js-modal-nav-link-parent > a').each(function (i) {
                    var $parent = $(this).parent();
                    var id = $parent.data('id');
                    $(this).addClass('js-open-in-modal');
                    $(this).data('id', id);
                  });
                  $('.js-modal-nav-link').addClass('js-open-in-modal');

                  // update close button class if necessary
                  if (
                    $modal
                      .find('.js-modal-content main')
                      .hasClass('c-main--pad')
                  ) {
                    $modal
                      .find('.c-modal__close')
                      .addClass('c-modal__close--inverted');
                  }

                  // update link in the share buttons
                  $modal.find('.ssba-wrap a').each(function (i) {
                    var href = $(this).attr('href');
                    var u_href = href.replace(data.ajaxurl, url);
                    $(this).attr('href', u_href);
                  });

                  // add the link button
                  var $email_share_btn = $modal.find(
                    '.ssba-wrap a[data-site="email"]'
                  );
                  var button_markup =
                    '<a class="c-social-icon" href="' +
                    url +
                    '" title="Go to Page" data-open="new-tab"><i class="c-icon-link"></i></a>';
                  if ($email_share_btn.length) {
                    $(button_markup).insertAfter($email_share_btn);
                  } else {
                    // add the link to members pages
                    var $btn_print = $modal.find(
                      '.js-member-social-icons .ssba-wrap'
                    );
                    $('<li>' + button_markup + '</li>').insertBefore(
                      $btn_print
                    );
                  }

                  // add new class to ssba buttons to bind the custom button events
                  $modal.find('.ssba-wrap').addClass('js-ssba-wrap');

                  // remove print action from print button
                  $modal
                    .find('.js-ssba-wrap a[data-site=print]')
                    .attr('onclick', '')
                    .attr('href', url + '#print');

                  fix_share_special_chars();
                },
              });
            }
            //If clipboard.js exists, change the container to the modal
            if (typeof window.heateorSssClipboard != 'undefined')
              window.heateorSssClipboard.container = document.getElementById(
                'item-modal-' + id
              );
          }
        });

        /****************************************************************/
        /* Swiper Carousels with Scroll  - used on multiple pages */
        /*****************************************************************/

        var isMobile = testIsMobile();
        var carouselSwiper;

        if ($('.js-swiper').length) {
          if (!isMobile) {
            $('.js-swiper').removeClass('is-stacked').removeClass('is-initial');
            setTimeout(function () {
              carouselSwiper = initCarouselSwiper(isMobile, true);
            }, 10);
          } else {
            $('.js-swiper').removeClass('is-initial');
          }
        }

        // scroll to active project on mobile
        if ($('.js-swiper.is-stacked').length) {
          var $activeProject = $('.js-active-item');
          var extra_offset = 49; // the filters drawer height when it's pinned
          animateScroll($activeProject, extra_offset);
        }

        // Use History API to make the back button scroll you to the last clicked project
        $('.js-filtered-item').on('click', function (e) {
          e.preventDefault();
          var slug = $(this).data('slug');
          var newUrl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            '?res=' +
            slug;
          var title = $('head title');
          var stateObj = { slug: slug };
          history.pushState(stateObj, title, newUrl);

          // set a cookie of the current slide
          if (!testIsMobile()) {
            var currentSlide = carouselSwiper.activeIndex;
            var type = $('.js-swiper').data('type');
            Cookies.set('carousel_' + type, currentSlide);
          }

          if (!testIsDesktop()) {
            window.location = $(this).attr('href');
          }
        });

        /* ----- Scroll Multiple Items on Next/Prex buttons click ----- */
        var numSlides = getSlidesToScroll();

        $('.js-swiper-button-next-many').click(function (e) {
          e.preventDefault();
          var current = carouselSwiper.activeIndex;
          var total = carouselSwiper.slides.length;
          var nextSlideIndex = Math.min(current + numSlides - 1, total);
          carouselSwiper.slideTo(nextSlideIndex);
        });

        $('.js-swiper-button-prev-many').click(function (e) {
          e.preventDefault();
          var current = carouselSwiper.activeIndex;
          var $currentSlide = $(carouselSwiper.slides[current]);
          if ($currentSlide.offset().left < 0) {
            current++;
          }
          var prevSlideIndex = Math.max(current - numSlides + 1, 0);
          carouselSwiper.slideTo(prevSlideIndex);
        });

        /*****************************************************************/
        /* Carousel Filtering  */

        // filtering -----
        $('.js-filter-form').on('submit', function (event) {
          event.preventDefault();
          //hide the items and the parents
          $('.js-filtered-item')
            .removeClass('is-visible')
            .addClass('is-hidden')
            .parent()
            .addClass('is-hidden');

          var formData = $(this).serialize().split('&');
          var selector = '';
          for (var i = 0; i < formData.length; i++) {
            var hash = formData[i].split('=');
            //exclude empty / default values, term and size filters
            if (hash[1] === '' || hash[1] === '*' || hash[0] === 'term') {
              continue;
            }

            selector = selector + '[data-' + hash[0] + '~=' + hash[1] + ']';
          }

          // add class for visible items that can be filtered by the simple filters
          if (selector !== '') {
            $('.js-filtered-item').filter(selector).addClass('stage1'); // to test : show().parent().show();
          } else {
            $('.js-filtered-item').addClass('stage1');
          }

          //check the term
          var term = $('.js-filter-term').val().toLowerCase();
          if (term !== '') {
            $('.js-filtered-item.stage1').each(function () {
              //see if the term matches
              var content = $(this)
                .find('.js-filtered-by-term')
                .text()
                .toLowerCase();
              // replace apostrophes with straight ones
              content = content.replace('’', "'");
              if (content.indexOf(term) > -1) {
                $(this).removeClass('stage1').addClass('is-visible');
              }
            });
          } else {
            $('.js-filtered-item.stage1')
              .removeClass('stage1')
              .addClass('is-visible');
          }

          $('.js-filtered-item.is-visible')
            .removeClass('is-hidden')
            .parent()
            .removeClass('is-hidden');

          // update carousel size if necessary
          if (!$('.js-swiper').hasClass('is-stacked')) {
            carouselSwiper.update(true);
            // hack fix for the scrollbar
            // if scrollbar dissappears, then when it's added back in, the scroll drag element's width is not computed correctly
            setTimeout(function () {
              carouselSwiper.update(true);
            }, 10);
          }

          // scroll the stacked container to the top if filters are sticky
          if (
            $('.js-filters-drawer.is-pinned').length &&
            $('.js-swiper.is-stacked').length
          ) {
            var offset =
              $('.js-filters-drawer-placeholder').offset().top -
              $('.js-filters-drawer-placeholder').outerHeight();
            $('html, body').animate(
              {
                scrollTop: offset,
              },
              700
            );
          }
        });

        // filter on selct change
        $('.js-filter-select').on('change', function () {
          $('.js-filter-form').trigger('submit');
          //close drawer
          $('.js-filters-drawer-trigger').removeClass('is-active');
          $('.js-filters-drawer-content').removeClass('is-active');
        });

        // clear filters
        $('.js-clear-filters').on('click', function (e) {
          e.preventDefault();
          $('.js-filter-select').val('*');
          $('.js-filter-term').val('');
          $('.js-filter-form').trigger('submit');
        });

        /*****************************************************************/
        // Filters Drawer

        $('.js-filters-drawer-trigger').on('click', function (e) {
          e.preventDefault();
          $(this).toggleClass('is-active');
          $('.js-filters-drawer-content').toggleClass('is-active');
          e.stopPropagation();
        });
        // do not bubble clicks on input and selects inside the drawer content
        $('.js-filters-drawer-content input').on('click', function (e) {
          e.stopPropagation();
        });
        $('.js-filters-drawer-content select').on('click', function (e) {
          e.stopPropagation();
        });
        //close filters drawer on document click
        $(document).on('click', function () {
          $('.js-filters-drawer-trigger').removeClass('is-active');
          $('.js-filters-drawer-content').removeClass('is-active');
        });

        // pinning the filters drawer
        pin_filters();
        $(window).scroll($.throttle(100, pin_filters));

        /*****************************************************************/
        // Print
        /*****************************************************************/
        // print window at page load based on the #print hash because window.print() is not working as expected in IE
        if (window.location.hash === '#print') {
          addPrintImgSrc();
          setTimeout(function () {
            $('.ssba-wrap a[data-site=print]')
              .attr('href', '#print')
              .trigger('click');
            setTimeout(function () {
              window.close();
            }, 100);
          }, 300);
        }

        // if on desktop add the print image source
        if (testIsDesktop) {
          addPrintImgSrc();
        }

        // print link
        $('.js-print-screen').on('click', function (e) {
          e.preventDefault();
          e.stopPropagation();
          window.print();
        });

        // do stuff before print
        var beforePrint = function () {
          // add img source
          addPrintImgSrc();
        };

        var afterPrint = function () {
          if (window.location.hash === '#print') {
            setTimeout(function () {
              window.close();
            }, 100);
          }
        };

        if (window.matchMedia) {
          var mediaQueryList = window.matchMedia('print');
          mediaQueryList.addListener(function (mql) {
            if (mql.matches) {
              beforePrint();
            } else {
              afterPrint();
            }
          });
        }

        window.onbeforeprint = beforePrint;

        // prevent default print behaviour when the modal is open
        $(document).on('keydown', function (e) {
          if (
            $('body').hasClass('has-modal-open') &&
            (e.metaKey || e.ctrlKey) &&
            e.keyCode === 80
          ) {
            e.preventDefault();
            $('body > .mfp-wrap .js-ssba-wrap a[data-site=print]').trigger(
              'click'
            );
            return false;
          }
        });

        /*****************************************************************/
        // Share Buttons
        /*****************************************************************/
        // fix apostrophes in email subject line
        fix_share_special_chars();

        // code taken from the ssba plugin
        // the event handler is binded to the buttons diferently
        // so it works for the dinamically added buttons
        // and used a custom selector, so default buttons are not affected
        $('body').on('click', '.js-ssba-wrap a', function (event) {
          // don't go the the href yet
          event.preventDefault();

          if (jQuery(this).data('open') === 'new-tab') {
            // if it's the link button
            window.open(jQuery(this).attr('href'), '_blank');
          } else if (jQuery(this).data('facebook') === 'mobile') {
            // if it's facebook mobile
            FB.ui(
              {
                method: 'share',
                mobile_iframe: true,
                href: jQuery(this).data('href'),
              },
              function (response) {}
            );
          } else {
            // these share options don't need to have a popup
            if (
              jQuery(this).data('site') === 'email' ||
              jQuery(this).data('site') === 'pinterest'
            ) {
              // just redirect
              window.location.href = jQuery(this).attr('href');
            } else {
              // prepare popup window
              var width = 575,
                height = 520,
                left = (jQuery(window).width() - width) / 2,
                top = (jQuery(window).height() - height) / 2,
                opts =
                  'status=1' +
                  ',width=' +
                  width +
                  ',height=' +
                  height +
                  ',top=' +
                  top +
                  ',left=' +
                  left;

              // open the share url in a smaller window
              var newW = window.open(jQuery(this).attr('href'), 'share', opts);

              //print the window based on the #print hash because window.print() is not working as expected in IE
              // if( jQuery(this).data('site') === 'print' ) {
              //     $(newW.document.body).attr('onload', 'window.focus(); window.print(); window.close()');
              //     newW.document.close();
              // }
            }
          }
        });

        /*****************************************************************/
        // Resize - smartresize
        /*****************************************************************/
        $(window).smartresize(function () {
          //main menu
          pin_top_menu();

          //news banner
          setNewsBannerHeight();

          //set the sidebar height
          setSidebarHeight();

          // swiper carousel
          if ($('.js-swiper').length) {
            // update
            if (!$('.js-swiper').hasClass('is-stacked')) {
              carouselSwiper.update();
            }
            // check if init/destroy is needed
            var isMobileNow = testIsMobile();
            if (isMobileNow !== isMobile) {
              // set the new state
              isMobile = isMobileNow;
              // init or destroy the slider
              if (isMobile === true) {
                $('.js-swiper').addClass('is-stacked');
                carouselSwiper.destroy(true, true);
                // destroy also deletes all inline styles
                // restore original inline styles
                $('.js-swiper-slide').each(function () {
                  var inlineStyle = $(this).data('style');
                  if (inlineStyle) {
                    $(this).attr('style', inlineStyle);
                  }
                });
              } else {
                $('.js-swiper').removeClass('is-stacked');
                carouselSwiper = initCarouselSwiper(isMobile);
              }
            }

            //  reset scroll multiple on Next/Prex buttons clicks if necessary
            if ($('.js-swiper-button-next-many').length) {
              var numSlidesNow = getSlidesToScroll();
              if (numSlidesNow !== numSlides) {
                numSlides = getSlidesToScroll();
              }
            }
          }

          //make sure filters are where they need to be
          pin_filters();

          //swap image sources
          change_image_sources();
        });
      },
    },
    // Home page
    home: {
      init: function () {
        // fancy select -----
        $('.js-homepage-fancy-select')
          .fancySelect()
          .on('change.fs', function () {
            $(this).trigger('change.$');
          });

        $('.js-homepage-fancy-select').on('change', function () {
          var is_reveal = $(this).find('option:selected').data('act');
          if (is_reveal === 'reveal-projects') {
            $('.js-projects-wall').addClass('is-active');
          }
        });

        var intervals = [5000, 7000, 9000, 11000, 13000, 15000, 17000];
        $('.js-wall-carousel').each(function () {
          var timing = intervals[Math.floor(Math.random() * intervals.length)];

          new Swiper($(this), {
            loop: true,
            preloadImages: false,
            lazyLoading: true,
            effect: 'fade',
            slidesPerView: 1,
            fadeEffect: {
              crossFade: true,
            },
            autoplay: timing,
            speed: 1500,
          });
        });

        setTimeout(function () {
          var $heroTitle = $('.c-hero--animation .c-hero__title');
          var $heroTitleSub = $('.c-hero--animation .c-hero__title-sub');
          $heroTitle.animate({ opacity: 1 }, 750, function () {
            $heroTitleSub.animate({ opacity: 1 }, 750, function () {
              $('.js-hero').addClass('is-animation-finished');
            });
          });
        }, 1000);

        // hero select nav -----
        $('.js-select-nav-trigger').on('click', function (e) {
          e.preventDefault();
          $(this).toggleClass('is-active');
          $('.js-select-nav-list').toggleClass('is-active');
          e.stopPropagation();
        });

        $('.js-select-nav-list > li > a').on('click', function (e) {
          e.preventDefault();
          var is_reveal = $(this).data('act');
          if (is_reveal === 'reveal-projects') {
            if ($('.js-projects-wall').css('display') !== 'none') {
              if ($('.js-projects-wall').hasClass('images-loaded')) {
                $('.js-projects-wall').addClass('is-active');
              }
            } else {
              window.location = $(this).attr('href');
            }
          } else {
            window.location = $(this).attr('href');
          }
          e.stopPropagation();
        });

        // close select on document click
        $(document).on('click', function () {
          $('.js-select-nav-list').removeClass('is-active');
          $('.js-select-nav-trigger').removeClass('is-active');
        });

        // news-ticker -----
        var $ticker_speed = $('.js-news-ticker-slider').data('speed');
        var newsTicker = new Swiper($('.js-news-ticker-slider'), {
          slidesPerView: 1,
          loop: true,
          pagination: '.swiper-pagination',
          paginationClickable: true,
          autoplay: $ticker_speed * 1000
        });

        // slider -----
        // load projects
        /*
        load_homepage_projects();

        $(window).smartresize(function () {
          // make sure projects are loaded
          load_homepage_projects();
        });
        */
      },
    },
    // Posts page
    posts_page: {
      init: function () {
        // console.log("posts page");
        // JavaScript to be fired on posts page
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
    single_post: {
      init: function () {},
    },
    single_projects: {
      init: function () {},
    },
    search_results: {
      init: function () {
        $('.js-select2').select2();

        // load more with filtering init -----
        $('.js-masonry-load-more-filter').loadMoreFilterMasonry();
      },
    },
    page_template_page_news_events_php: {
      init: function () {
        /*****************************************************************/
        // News & Events Page

        // nano-scroller -----
        $('.js-nano-scroller').nanoScroller({
          paneClass: 'c-nano__pane',
          sliderClass: 'c-nano__slider',
          contentClass: 'c-nano__content',
          sliderMaxHeight: 400,
        });

        // banner content -----
        setNewsBannerHeight();
        var $list = $('.js-news-banner__list');
        var itemsCount = $list.find('li').length;
        var ind = 2;
        var interval = $list.data('interval') ? $list.data('interval') : '3000';
        var timer = setInterval(function () {
          var $element = $(
            '.js-news-banner__list li:nth-child(' + ind + ')'
          ).find('.js-news-banner__item');
          setItemActive($element);
          ind++;
          if (ind > itemsCount) {
            ind = 1;
          }
        }, interval);

        $('.js-news-banner__item').on('click', function (e) {
          e.preventDefault();
          var $element = $(this);
          setItemActive($element);
          clearInterval(timer);
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var namespace = fwd;
      funcname = funcname === undefined ? 'init' : funcname;
      if (
        func !== '' &&
        namespace[func] &&
        typeof namespace[func][funcname] === 'function'
      ) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      UTIL.fire('common');

      $.each(
        document.body.className.replace(/-/g, '_').split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
        }
      );
    },
  };

  $(document).ready(UTIL.loadEvents);

  function pin_top_menu() {
    var top = $(window).scrollTop();
    if (top < 10) {
      $('.c-site-head').removeClass('is-pinned');
    } else {
      var $is_pinned = $('.c-site-head').hasClass('is-pinned');
      if (!$is_pinned) {
        $('.c-site-head').addClass('is-pinned');
      }
    }
  }

  function animateScroll($target, extra_offset) {
    extra_offset = typeof extra_offset !== 'undefined' ? extra_offset : 0;
    if ($target.length) {
      var $header_height = $('.c-site-head').outerHeight();
      var $scroll_amount = $target.offset().top - $header_height - extra_offset;
      $('html, body').animate(
        {
          scrollTop: $scroll_amount,
        },
        700
      );
    }
  }

  /**
   * Set the height of the news banner
   */
  function setNewsBannerHeight() {
    var $banner = $('.js-news-banner');
    if ($banner.css('display') !== 'none') {
      $banner.css('height', '80vh');
      var banner_h = $banner.outerHeight();
      var list_h = $('.js-news-banner__list').outerHeight();
      var header_h = $('.c-site-head').outerHeight();
      if (banner_h >= list_h + header_h) {
        $banner.height(list_h + header_h);
      }
    }
  }

  /**
   * Function called when clicking or auto-sliding the news banner items.
   * It makes element active, displays the correct content on the left and scrolls the list container if necessary
   * @param  {jquery element} $element  : the link that has been clicked or - in the case of auto-sliding - the element taht becomes active
   * @return -
   */
  function setItemActive($element) {
    // add active class
    $('.js-news-banner__item').removeClass('is-active');
    $element.addClass('is-active');
    // show correct details
    var cid = $element.data('id');
    $('.js-news-banner__content-container').removeClass('is-active');
    $('.js-news-banner__content-container[data-id=' + cid + ']').addClass(
      'is-active'
    );
    // check if content needs scrolling
    if ($('.js-nano-scroller').hasClass('has-scrollbar')) {
      var $container = $('.js-nano-scroller__content');
      var containerScrollTop = $container.scrollTop();
      var elPositionTop = $element.position().top;

      // make sure element is in view at the top
      // true when: $container.scrollTop() <= $element.position().top
      if (containerScrollTop > elPositionTop) {
        // element is out, above
        // scroll to element's top
        $container.animate(
          {
            scrollTop: elPositionTop,
          },
          300
        );
      } else {
        // make sure element is in view at the bottom
        // true when: $element.position().top + $element.outerHeight() <= $container.scrollTop() + $container.outerHeight()
        var containerH = $container.outerHeight();
        var elH = $element.outerHeight();

        if (elPositionTop + elH > containerScrollTop + containerH) {
          // element is out, under
          // scroll to element's bottom
          var scrollTop = elPositionTop + elH - containerH;
          $container.animate(
            {
              scrollTop: scrollTop,
            },
            300
          );
        }
      }
    }
  }

  /**
   * Function that sets the height of the sidebar
   */
  function setSidebarHeight() {
    //if we have a main with sidebar
    if ($('.js-main-w-side').length) {
      var $sidebar = $('.js-sidebar');
      $sidebar
        .removeClass('has-height')
        .removeClass('has-sticky-widget')
        .height('auto');
      if ($(window).width() >= 980) {
        $('.js-main-w-side').imagesLoaded(function () {
          var mainHeight = $('.js-main-w-side').height();
          if ($sidebar.height() < mainHeight) {
            $sidebar.height(mainHeight).addClass('has-sticky-widget');
          }
          //add has height class no matter what, to reveal the bottom widget
          $sidebar.addClass('has-height');
        });
      }
    }
  }

  /**
   * Function that initializes the slider in the hero
   */
  function initHeroSlider() {
    if ($('.js-slider').length) {
      $('.js-slider')
        .not('.slick-initialized')
        .each(function (i) {
          var play_speed = $(this).data('speed');
          var autoplaySpeed = play_speed ? play_speed : 5000;
          $(this).slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: autoplaySpeed,
          });
        });
    }
  }

  /**
   * Function that initializes the swiper carousel, used on awards winners, publications and selection committee members list
   * @param  {Boolean} isMobile [ are we on a small viewport ]
   * @return {object}          [the slider instance]
   */
  function initCarouselSwiper(isMobile, useActive) {
    useActive = typeof useActive !== 'undefined' ? useActive : false;

    var grab = isMobile ? false : true;
    var $swiper = $('.js-swiper');
    var space = $swiper.data('space');
    var spaceBetween = typeof space !== 'undefined' ? parseInt(space, 10) : 0;

    var initialSlide = 0;
    if (useActive) {
      var $activeSlide = $('.js-active-item');
      if ($activeSlide.length) {
        // check for a cookie
        var type = $('.js-swiper').data('type');
        var prevIndex = Cookies.get('carousel_' + type);
        if (typeof prevIndex !== 'undefined') {
          initialSlide = prevIndex;
        } else {
          var visible = $(window).width() / $activeSlide.width();
          initialSlide =
            $activeSlide.parent().index() - Math.floor(visible / 2);
          if (initialSlide < 0) {
            initialSlide = 0;
          }
          // need another formula for projects?
        }
      }
    }

    var options = {
      scrollbar: '.js-swiper-scrollbar',
      scrollbarHide: false,
      scrollbarDraggable: true,
      nextButton: '.js-swiper-button-next',
      prevButton: '.js-swiper-button-prev',
      slidesPerView: 'auto',
      spaceBetween: spaceBetween,
      grabCursor: grab,
      initialSlide: initialSlide,
    };

    var sliderInstance = new Swiper($swiper, options);

    sliderInstance.on('onScrollbarDragEnd', function () {
      if (sliderInstance.isEnd) {
        $('.js-swiper-button-next').addClass('swiper-button-disabled');
      } else if (sliderInstance.isBeginning) {
        $('.js-swiper-button-prev').addClass('swiper-button-disabled');
      }
    });

    return sliderInstance;
  }

  /**
   * Function that tests weather we are under a certain brakpoint
   * @return {Boolean} true if we are unedr the mobile breakpoint, false if not
   */
  function testIsMobile() {
    if ($(window).width() >= 770) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Function that tests weather we are under a certain brakpoint , but over another
   * @return {Boolean} true if we are unedr the desktop breakpoint and over mobile breakpoint, false if not
   */
  function testIsTablet() {
    if ($(window).width() >= 770 && $(window).width() < 980) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Function that tests weather we are under a certain brakpoint , but over another
   * @return {Boolean} true if we are unedr the desktop breakpoint and over mobile breakpoint, false if not
   */
  function testIsDesktop() {
    if ($(window).width() >= 980) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Function that returns the number of slides to scroll at once in the carousel on Selection Committee List page
   * @return {Number} [number of slides]
   */
  function getSlidesToScroll() {
    if ($(window).width() >= 900) {
      return 3; // 6 / 2 cause there are 2 / col
    } else {
      return 2; // 4 / 2 cause there are 2 / col
    }
  }

  /**
   * Function that pinns the filters on scroll
   */
  function pin_filters() {
    var $placeholder = $('.js-filters-drawer-placeholder');
    if (testIsMobile() && $placeholder.length) {
      var top = $(window).scrollTop();
      var position = $placeholder.offset().top;
      var header_height = $('.c-site-head').height();
      if (top < position - header_height) {
        $('.js-filters-drawer').removeClass('is-pinned');
        $placeholder.removeClass('is-replacing');
      } else {
        $('.js-filters-drawer').addClass('is-pinned');
        $placeholder.addClass('is-replacing');
      }
    }
  }

  /**
   * Function called on the hoempage, that loads in project cards in the hero area
   */
  function load_homepage_projects() {
    // check if porjects wall is visible
    var $projectsContainer = $('.js-projects-wall');
    if ($projectsContainer.css('display') !== 'none') {
      // check that projects aren't already loaded
      if (!$projectsContainer.find('.js-swiper-slide').length) {
        $.ajax({
          url: data.ajaxurl,
          type: 'post',
          data: {
            action: 'bfd_get_homepage_projects',
            _ajax_nonce: data.home_nonce,
            do: 'load_projects',
          },

          beforeSend: function () {},
          success: function (response) {
            $projectsContainer.find('.swiper-wrapper').append(response);
            //init slider
            var homeSwiper = new Swiper('.js-swiper-home', {
              // pagination: '.js-swiper-pagination',
              // paginationClickable: true,
              nextButton: '.js-swiper-button-next',
              prevButton: '.js-swiper-button-prev',
              slidesPerView: 'auto',
              loop: true, // doesn't work correctly - remove when there are enough projects
              //loopedSlides: 1
            });

            $projectsContainer.imagesLoaded(function () {
              $projectsContainer.addClass('images-loaded');
              $('.js-browse-winners').removeClass('is-loading');
              $('.js-spinner').removeClass('is-visible');
              $('.js-hero').addClass('has-images-loaded');
            });
            // http://codex.wordpress.org/AJAX_in_Plugins#The_post-load_JavaScript_Event
            $(document.body).trigger('post-load');
          },
        });
      }
    } else {
      $('.js-browse-winners').removeClass('is-loading');
      $('.js-spinner').removeClass('is-visible');
    }
  }

  /**
   * Function called to swap the background image url depending on screen size
   */
  function change_image_sources() {
    $('.js-swap-source').each(function (i) {
      var $element = $(this);
      if (testIsMobile()) {
        var bgMobile = $element.data('image-mobile');
        $element.css('background-image', 'url(' + bgMobile + ')');
      } else {
        var bgDesktop = $element.data('image-desktop');
        var bgTablet = $element.data('image-tablet');

        if (typeof bgDesktop !== 'undefined') {
          //we have an image that swaps 3 times
          if (testIsTablet()) {
            $element.css('background-image', 'url(' + bgTablet + ')');
          } else {
            $element.css('background-image', 'url(' + bgDesktop + ')');
          }
        } else {
          $element.css('background-image', 'url(' + bgTablet + ')');
        }
      }
    });
  }

  /**
   * Function that changes the apostrophe in the email subject
   */
  function fix_share_special_chars() {
    $('.ssba-wrap [data-site=email]').each(function () {
      var old = $(this).attr('href');
      var href = old.replace('%26#8217;', '%27');
      $(this).attr('href', href);
    });
  }

  function addPrintImgSrc() {
    $('.js-img-print').each(function () {
      $(this).attr('src', $(this).data('src'));
    });
  }
})(jQuery); // Fully reference jQuery after this point.
