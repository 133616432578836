(function($){
    $.fn.loadMoreMasonry = function () {
        return this.each(function() {
            var initialLoad = true;
            var page = 1;

            var $msnContainer = $(this).find('.js-masonry-container');
            var $loader = $(this).find('.js-loader');
            var $loadBtn = $(this).find('.js-load-more-btn');

            $msnContainer.imagesLoaded( function() {
                $msnContainer.find('.js-masonry-item').removeClass('u-hide');
                $msnContainer.masonry({
                    itemSelector: '.js-masonry-item',
                    // use sizer element for option
                    columnWidth: '.js-masonry-sizer',
                    percentPosition: true
                });
                $loader.addClass('u-hide');

                if ( page < data.max_num_pages ) {
                    $loadBtn.removeClass('u-hide');
                }

                initialLoad = false;
            });

            //load more function
            var loadPosts = function() {
                $.ajax({
                  url: data.ajaxurl,
                  type: 'post',
                  data: {
                      'action': 'bfd_load_more_masonry',
                      'paged': page,
                      '_ajax_nonce': data.nonce,
                      'query_vars' : data.query_vars
                  },
                  beforeSend: function () {
                    $loadBtn.addClass('u-hide');
                    $loader.removeClass('u-hide');
                  },
                  success: function (response) {
                    $msnContainer.append(response);
                    
                    // http://codex.wordpress.org/AJAX_in_Plugins#The_post-load_JavaScript_Event
                    $(document.body).trigger('post-load');

                    var $new_elements = $('.js-masonry-item.u-hide');
                    $msnContainer.imagesLoaded( function() {
                      $msnContainer.find('.js-masonry-item.u-hide').removeClass('u-hide');
                      $msnContainer.masonry('appended', $new_elements);
                      $loader.addClass('u-hide');

                      if ( page < data.max_num_pages ) {
                        $loadBtn.removeClass('u-hide');
                      }
                    });
                  }
                });
            };

            //load more button
            $loadBtn.on('click', function(event){
                event.preventDefault();
                page++;
                loadPosts();
            });
        });
    };
})(jQuery);